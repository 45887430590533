<template>
<div class="advanced-wrap">
  <div class="advanced-search">
    <div class="title">高级检索</div>
    <div class="list-wrap">
      <div class="list" v-for="(item, index) in state.list" :key="item">
        <div class="list-fst-item" v-if="index===0">
          <div class="dropdown" @click="showActionSheetHandle(index)">
            <div class="text van-ellipsis">{{ item.value }}</div>
            <Icon class="icon" name="arrow-down" />
          </div>
          <input class="fst-input" type="text" placeholder="请输入检索词" v-model="item.text">
        </div>
        <div class="list-item" v-else>
          <div class="dropdown" @click="showOPActionSheetHandle(index)">
            <span>{{ item.op }}</span>
            <Icon name="arrow-down" />
          </div>
          <div class="dropdown" @click="showActionSheetHandle(index)">
            <span>{{ item.value }}</span>
            <Icon name="arrow-down" />
          </div>
          <div class="input-wrap">
            <input class="input" type="text" placeholder="请输入检索词" v-model="item.text">
            <div class="close-icon-wrap">
              <div class="close-icon" >
                <Icon name="cross" size="12px" color="#fff" @click="subInput(index)"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="btn-wrap">
    <Button class="btn" type="primary" plain @click="clearInput">清空</Button>
    <Button class="btn" type="primary" plain @click="addInput">添加</Button>
    <Button class="btn" type="primary" @click="searchHandle" :color="showColor?'#007AFF':'#9ea7b4'">检索</Button>
  </div>
  <div class="history-wrap">
    <div class="title">历史检索式</div>
    <div class="list-wrap" v-if="state.hisList.length > 0">
      <div class="list-item" v-for="item in state.hisList" :key="item" @click="advanceItemHandle(item)">
        <div class="text">
          <div class="text-item" v-for="(text, index) in item.content.split('\t')" :key="index">
            <span class="op" v-if="text === 'AND' || text === 'OR'">{{text}}</span>
            <span class="tag" v-else>{{ text }}</span>
          </div>
        </div>
        <div class="date">{{ item.date }} {{ item.time }}</div>
        <!-- <Icon class="icon" name="cross" color="#fff" @click="delHandle(item.id)"/> -->
      </div>
    </div>
    <div class="empty" v-else>
      <Empty description="暂无数据"></Empty>
    </div>
  </div>
</div>
  <ActionSheet
    v-model:show="state.showActionSheet"
    :actions="state.actions"
    @select="onSelect"
  />
  <ActionSheet
    v-model:show="state.showOPActionSheet"
    :actions="state.OPActions"
    @select="onSelectOP"
  />
  <Popup
    v-model:show="state.showPicker"
    position="bottom"
  >
    <Picker
      :columns="state.columns"
      @confirm="pickerConfirm"
      @cancel="pickerCancel"
    />
  </Popup>
</template>

<script>
import { Icon, Button, Dialog, Picker, Popup, Toast, ActionSheet, Empty } from 'vant'
import { reactive, computed } from 'vue'
import { useRouter } from 'vue-router'
import { getHistoryRetrieval } from '@/api/'
export default {
  name: 'Advanced',
  components: { Icon, Button, Picker, Popup, ActionSheet, Empty },
  setup () {
    // document.title = '高级检索'
    const router = useRouter()
    const state = reactive({
      list: [
        {
          op: '',
          key: 'Title',
          text: '',
          value: '标题'
        }
      ],
      hisList: [],
      time: {
        op: 'AND',
        key: 'year',
        text: '2015-2021',
        value: '时间'
      },
      showPicker: false,
      showActionSheet: false,
      showOPActionSheet: false,
      actions: [
        { name: '标题', key: 'Title' },
        { name: '关键词', key: 'Keywords' },
        { name: '摘要', key: 'Abstract/summary' },
        { name: 'DOI', key: 'DOI' },
        { name: 'PMID', key: 'PMID' },
        { name: '期刊', key: 'Journal' },
        { name: '第一作者', key: 'First Author' },
        { name: '所有作者', key: 'Author' }
      ],
      OPActions: [
        { name: 'AND', key: 'AND' },
        { name: 'OR', key: 'OR' }
      ],
      actionsIndex: 0,
      OPActionsIndex: 0
    })
    getHistoryRetrieval({
      search_type: 'advanced_search'
    }).then(res => {
      console.log(res)
      if (res.code === 200) {
        state.hisList = res.data
      }
    })
    const showTimeHandle = () => {
      state.showPicker = true
    }
    const addInput = () => {
      if (state.list.length < 5) {
        state.list.push({ op: 'AND', key: 'Title', text: '', value: '标题' })
      }
    }
    const subInput = (index) => {
      Dialog.confirm({
        title: '确认删除本条记录？',
        confirmButtonColor: '#007aff'
      })
        .then(() => {
          state.list.splice(index, 1)
        })
        .catch(() => {
          // on cancel
        })
    }
    const clearInput = () => {
      Dialog.confirm({
        title: '确认清空所有内容？',
        confirmButtonColor: '#007aff'
      })
        .then(() => {
          state.list = [
            {
              op: 'AND',
              key: 'title',
              text: '',
              value: '标题'
            }
          ]
        })
        .catch(() => {
          // on cancel
        })
    }
    const pickerConfirm = (val) => {
      // console.log(val)
      if (val[0] > val[1]) {
        Toast('开始时间不得大于结束时间')
      } else {
        state.time.text = val[0] + '-' + val[1]
        state.showPicker = false
      }
    }
    const pickerCancel = () => {
      state.showPicker = false
    }
    const showActionSheetHandle = (index) => {
      state.showActionSheet = true
      state.actionsIndex = index
    }
    const showOPActionSheetHandle = (index) => {
      state.showOPActionSheet = true
      state.OPActionsIndex = index
    }
    const onSelect = (val) => {
      state.list[state.actionsIndex].value = val.name
      state.list[state.actionsIndex].key = val.key
      state.showActionSheet = false
    }
    const onSelectOP = (val) => {
      state.list[state.OPActionsIndex].op = val.name
      state.showOPActionSheet = false
    }
    const showColor = computed(() => {
      if (state.list.filter(item => !item.text.match(/^[ ]*$/)).length > 0) {
        return true
      } else {
        return false
      }
    })
    const searchHandle = () => {
      if (state.list.filter(item => !item.text.match(/^[ ]*$/)).length > 0) {
        const obj = Object.assign({}, state.list.filter(item => !item.text.match(/^[ ]*$/)))
        let arr = []
        let str = ''
        for (const i in obj) {
          arr.push(obj[i])
        }
        arr = arr.map(item => ({
          op: item.op,
          key: item.key,
          text: item.text
        }))
        console.log(arr)
        arr.forEach(item => {
          str += `${item.op},${item.key}=${item.text},`
        })
        console.log(str.replace(/^(\s|,)+|(\s|,)+$/g, ''))
        router.push(`/advanceList?search=${str.replace(/^(\s|,)+|(\s|,)+$/g, '')}`)
      }
    }
    const delHandle = (val) => {
      console.log(val)
    }
    const advanceItemHandle = (val) => {
      console.log(val)
      router.push(`/advanceList?search=${val.search_content}`)
    }
    return {
      state,
      addInput,
      subInput,
      clearInput,
      showTimeHandle,
      pickerConfirm,
      pickerCancel,
      showActionSheetHandle,
      onSelect,
      onSelectOP,
      showOPActionSheetHandle,
      searchHandle,
      showColor,
      delHandle,
      advanceItemHandle
    }
  }
}
</script>

<style lang="less" scoped>
.advanced-wrap{
  .advanced-search{
    padding: 21px 15px 21px 20px;
    background: #fff;
    border-bottom: 1px solid #F2F2F2;
    .title{
      font-size: 16px;
      font-weight: bold;
      color: #212325;
    }
    .list-wrap{
      margin-top: 20px;
      input{
        font-size: 14px;
        line-height:normal;
        color: #333333;
        padding: 0 12px;
        &::-webkit-input-placeholder{
          font-size: 14px;
          line-height:normal;
          color: #8D99A6;
        }
      }
      .list-fst-item{
        display: flex;
        justify-content: space-between;
        .dropdown{
          width: 60px;
          height: 32px;
          border: 1px solid #AAADB7;
          border-radius: 4px;
          font-size: 14px;
          font-weight: 400;
          color: #8D99A6;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 10px;
          color: #333;
          .text{
            max-width: 46px;
          }
        }
        .fst-input{
          width: 220px;
          height: 32px;
          border: 1px solid #AAADB7;
          border-radius: 4px;
        }
      }
      .list-item{
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        .dropdown{
          width: 60px;
          height: 32px;
          border: 1px solid #AAADB7;
          border-radius: 4px;
          font-size: 14px;
          font-weight: 400;
          color: #8D99A6;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 10px;
          color: #333;
          .text{
            max-width: 46px;
          }
        }
        .input-wrap{
          width: 150px;
          height: 34px;
          position: relative;
          background: #F66868;
          border-radius: 4px;
          display: flex;
          .input{
            width: 124px;
            height: 32px;
            border: 1px solid #AAADB7;
            border-radius: 4px;
            clip-path: polygon(150px 19px, 130px 0, 0 0, 0 36px, 150px 36px);
          }
          .close-icon-wrap{
            position: absolute;
            right: 0;
            top: 0;
            .close-icon{
              position: relative;
              right: 0;
              top: 0;
              display: flex;
              transform: scale(0.6) translateX(0px) translateY(0px);
            }
          }
        }
      }
      .time-wrap{
        font-size: 14px;
        color: #8D99A6;
        display: flex;
        margin-top: 20px;
        justify-content: space-between;
        align-items: center;
        .time-item{
          height: 32px;
          width: 250px;
          border: 1px solid #AAADB7;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 12px;
          .text{
            display: flex;
            span{
              margin: 0 6px;
            }
          }
        }
      }
    }
  }
  .btn-wrap{
    padding: 20px 10px 10px 15px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    .btn{
      width: 110px;
      height: 32px;
      border-radius: 4px;
    }
  }
  .history-wrap{
    padding: 21px 15px 21px;
    background: #fff;
    margin-top: 12px;
    .title{
      font-size: 16px;
      font-weight: bold;
      color: #212325;
    }
    .list-wrap{
      .list-item{
        margin-top: 20px;
        border-radius: 4px;
        position: relative;
        // background: #F66868;
        border: 1px solid #F2F2F2;
        border-radius: 4px;
        .text{
          font-size: 14px;
          line-height: 20px;
          padding: 16px 12px;
          color: #212325;
          background: #fff;
          // clip-path: polygon(100% 19px,calc(100% - 20px) 0, 0 0, 0 100%, 100% 100%);
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          .text-item {
            margin-bottom: 12px;
            .tag {
              padding: 4px 12px;
              background: #EBF5FF;
              border-radius: 14px;
            }
            .op {
              margin: 0 8px;
            }
          }
        }
        .date {
          padding: 0 12px;
          font-size: 12px;
          color: #999999;
          line-height: 17px;
          margin-bottom: 12px;
        }
        .icon{
          position: absolute;
          right: 0;
          top: 0;
          transform: scale(0.4) translate(14px,-14px);
        }
      }
    }
    .empty {
      min-height: 200px;
    }
  }
}
</style>
